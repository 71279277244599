<script>
export default {
  name: "TextareaComponent",
  props : {
    maxlength : {
      type : Number
    },
    placeholder : {
      type : String
    },
    value : {
      type : String
    }
  },
  data(){
    return {
      count : 0,
      redCount : false,
      content : ""
    }
  },
  mounted(){
    
    if(this.value){
      
      this.count = this.value.length;
      this.content = this.value;
      this.redCount = this.count >= this.maxlength;
    }
  },
  methods : {
    input(ev){
      this.count = ev.target.value.length;
      this.content = ev.target.value;
      this.$emit('enter', this.content)
      if(this.count >= this.maxlength){
        this.redCount = true
        ev.preventDefault();
      }
      else{
        this.redCount = false;
      }
    }
  }
}
</script>

<template>
  <div class="textarea w-full">
  <textarea  class="ui-input w-full" v-model="content" rows="3" @input="input" :placeholder="placeholder" name="description"></textarea>
    <span :class="(redCount) ? 'symcount red' : 'symcount'">{{count}} / {{maxlength}}</span>
  </div>


</template>

<style scoped>
.textarea{
  position: relative;


}
  .symcount{
    position: absolute;

    padding: 3px;
    border-radius: 2px;
    bottom: 15px;
    color: #4F4F4F99;
    font-size: 12px;
    right: 15px;
  }
  .red{
    color: red !important;
  }
</style>