<template>
    <div class="offer-bg" v-if="show"></div>
    <img v-if="show" src="@/assets/icons/close.svg" @click="closeOffer()" style="background-color: rgba(255,255,255,1); border-radius: 50%; padding: 4px; position: fixed; right: 20px; top: 20px" height="24" width="24" alt="">
    <div class="full-offer" v-show="show" ref="full">
        <span>{{ $t("freelance.ot") }} {{contact}}</span>
        <p>{{ text }}</p>
        <span class="offer-spin">{{$t("freelance.spin")}}</span>
        <Button class="w-full mt-2" style="font-size: 13px;" @click="toDM()">{{$t("freelance.contact")}}</Button>
        <Button class="w-full mt-2" style="font-size: 13px;" @click="share()"  variant="dark" >{{$t("freelance.share")}}</Button>
    </div>
    <div class="offer">
        <p>{{ sliceText() }}</p>
        <Button class="w-full mt-2" style="font-size: 13px;" @click="showOffer()">{{ $t("freelance.podrobnee") }}</Button>
    </div>
</template>
<script>
import { initUtils } from '@telegram-apps/sdk';
import Button from '../Button.vue';

    
    export default{
        components: {
            Button
        },
        name : "OfferComponent",
        data(){
            return {
                showText : "",
                show : false,
            }
        },
        props : {
            contact : {
                type : String,
            },
            id : {
                type : String,
            },
            text : {
                type : String,
            }
        },
        methods : {
            sliceText(){
                if(this.text.length > 100){
                    return this.text.slice(0,100) + '...';
                }
                else{
                    return this.text;
                }
            },
            showOffer(){
                this.show = true;
                setTimeout(() => {
                    this.$refs.full.style.scale = "1";    
                }, 10);
            },
            closeOffer(){
                this.$refs.full.style.scale = "0";   
                setTimeout(() => {
                    this.show = false
                }, 500);
            },
            toDM(){
                let utils = initUtils();
                let contact = this.contact;
                contact = contact.replaceAll(' ','').replaceAll('@','');
                utils.openTelegramLink("https://t.me/" + contact);
            },
            async share(){
                let prepared_id = await this.$api.get('telegram/shareOffer/' + this.$store.state.userInfo.id + '/' + this.id);
                this.$gram.WebView.postEvent('web_app_send_prepared_message', false, {id: prepared_id.id});
                
            }
        }
    }
</script>
<style scoped>
.offer{
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}
p{
    font-size: 13px;
    
}
.full-offer{
    position: fixed;
    top: 10%;
    padding: 20px;
    transition: .2s linear 0s;
    transform-origin: center center;
    width: 90vw;
    left: calc(50% - 45vw);
    background-color: #fff;
    overflow-y: scroll;
    height: 57%;

    scale: 0;
    border-radius: 10px;
}
.full-offer p{
    white-space: pre-wrap;
    max-width: 100%;
    overflow-y: scroll;
    height: 60%;
}
.offer-bg{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(5px);
}
.offer-spin{
    font-size: 10px;
}
</style>