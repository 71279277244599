<script>
export default {
  name: "ShopSlogan",
  props : {
    skeleton : {
      type : Boolean
    },
    text : {
      type : String,
    }
  }
}
</script>

<template>
  <span :class="(skeleton) ? 'a-skeleton shop-include' : 'shop-include'">{{text}}</span>
</template>

<style scoped>
.shop-include{
  background-color: #FFFFFF66;
  padding: 15px;
  border-radius: 15px;
  font-size: 12px;
  color: #2D2D2DB2;
  text-align: center;
  width: 300px;

}
.a-skeleton{
  border-radius: 10px;
}

</style>