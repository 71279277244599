<template>
        <ClientOrder v-if="provided_to == 'buyer'" :data="data"></ClientOrder>
        <SellerOrder v-if="provided_to == 'seller'" :data="data"></SellerOrder>
</template>
<script>
import ClientOrder from "@/views/Orders/ClientOrder.vue";
import SellerOrder from "@/views/Orders/SellerOrder.vue";
export default {
    name : "OrderView",
    components : {
        ClientOrder,SellerOrder
    },
    data(){
        return {
            provided_to : null,
            data : null,
        }
    },
    methods : {

    },
    async mounted(){
        let orderID = this.$route.params.id;
        // API GET ORDER 
        let orderINFO = await this.$api.get('orders/get/' + orderID);
        this.data = orderINFO.order;
        if(this.$store.state.userInfo._id == this.data.seller){
            // User seller
            this.provided_to = "seller"
        }
        else if(this.$store.state.userInfo._id == this.data.buyer){
            // User buyer
            this.provided_to = "buyer"
            console.log("buyer");
            
        }
        else{
            // this.$router.push('/')
            console.log(orderINFO.order);
        }
        
    }
    
    
}
</script>
<style scoped></style>