<template>
  <div class="error-view" v-if="error"></div>
  <div class="error-report-view" v-if="error">
      <span class="error-id">err: #{{ id }}</span>
      <h1 class="title">Погодите-ка!</h1>
      <p class="error-msg">Что-то странное случилось в ходе работы приложения, явно что-то не сработало, сообщите нам пожалуйста в поддержку о вашей проблеме <a href="#" class="tg-link" @click="openSupport()">@tagito_support</a> </p>
      <Button class="w-full mt-2" @click="close">Закрыть</Button>
    </div>
  <router-view/>
</template>

<script>
import { initUtils } from '@telegram-apps/sdk';
import Button from './components/Button.vue';


export default{
  name : "App",
  data(){
    return {
      error : false,
      id : null,
    }
  },
  mounted(){
    this.$events.on("ui-sentry-error",(errorID)=>{
      this.id = errorID;
      this.error = true;
    })
  },
  components:{
    Button
  },
  methods : {
    openSupport(){
      initUtils().openTelegramLink("https://t.me/tagito_support");
    },
    close(){
      this.error = false;
    }
  }
}
</script>
<style>
.error-report-view{
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #fff;
  position: fixed;
  min-height: 200px;
  width: 300px;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  z-index: 10;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  
}
.error-id{
  position: absolute;
  left: 5px;
  bottom: 0px;
  font-size: 8px;
  color: rgba(0,0,0,0.5);
}
.title{
  font-weight: 600;
}
#app{
    min-height: 100vh;
  }
.error-msg{
  line-height: 150%;
font-size: 13px;
}
.error-view{
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;
  z-index: 9;
  position: fixed;
}
.tg-link{
  color: rgb(36, 146, 215);
  font-weight: 500;
}
</style>
