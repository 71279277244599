<template>
    <div class="page">
        <div class="waiting-payment" v-if="waitingPayment" ref="waiting">
            <span>Ожидание оплаты</span>
            <video src="@/assets/loading.webm" autoplay muted playsinline="true" loop style="border-radius: 40px; height: 40px;"></video>
            <span class="time-tip">Это не займет много времени</span>
        </div>
        <PageBadge  :text="'Статус заказа'"></PageBadge>
        <div class="container">
            
            <div class="order-block bg-white mt-4 p-4 rounded-xl flex justify-between items-center" v-if="data.status == 'FINISHED'">
                <span>Заказ завершён</span>
                <img src="@/assets/icons/order_finished_1.svg" alt="">
            </div>
            <div class="order-block bg-white mt-4 p-4 rounded-xl flex justify-between items-center" v-if="data.status == 'CANCELED'">
                <span>Заказ отменён</span>
                <img src="@/assets/icons/order_canceled.svg" alt="">
            </div>
            <div class="order-block bg-white mt-4  p-4 rounded-xl">
                
                <div class="cart-product  max-w-full" v-for="item of data.cart" :key="item">
                    <div class="card-product-image" :style="`background-image: url(${'https://cdn.tagito.store/products/' + item.photos[0] + '.png'})`"></div>
                    <div class="cart-product-info w-full">
                        <span class="cart-product-name">{{ item.name }}</span>
                        <span class="cart-product-price">{{ item.price }} ₽</span>
                    </div>
                </div>
            </div>
            <div class="order-block bg-white mt-4 p-4 rounded-xl">
                <div class="status">
                    <div :class="`step ${(data.stage >= 1) ? 'completed' : ''}`">
                        <div class="v-stepper">
                            <div class="circle"></div>
                            <div class="line"></div>
                        </div>

                        <div class="content">
                            Заказ создан
                        </div>
                    </div>
                    
                    <!-- active -->
                    <div  :class="`step ${(data.stage >= 1) ? 'completed' : ''}`">
                        <div class="v-stepper">
                        <div class="circle"></div>
                        <div class="line"></div>
                        </div>

                        <div class="content">
                        Закад подтвержден продавцом
                        </div>
                    </div>
                    
                        <!-- empty -->
                    <div class="step">
                        <div class="v-stepper">
                            <div class="circle"></div>
                            <div class="line"></div>
                        </div>

                        <div class="content">
                            Товар в пути
                        </div>
                    </div>
                    
                    <!-- regular -->
                    <div class="step">
                        <div class="v-stepper">
                            <div class="circle"></div>
                            <div class="line"></div>
                        </div>

                        <div class="content">
                            Ожидает подтверждения от покупателя
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-block bg-white mt-4 p-4 rounded-xl flex flex-col gap-4" v-if="data.status == 'CREATED'">
                <span>Данные для доставки СДЭК</span>
                <CityInput @enter="setupCity" placeholder="Город"></CityInput>
                <PVZInput :code="selectedCity" v-if="selectedCity > 0" @enter="setupPVZ" placeholder="Адрес ПВЗ"/>
                <input type="text" v-model="lastname" name="lastname" class="ui-input w-full" placeholder="Фамилия">
                <input type="text" v-model="firstname" name="firstname" class="ui-input w-full" placeholder="Имя">
                <input type="text" v-model="parentname" name="parentname" class="ui-input w-full" placeholder="Отчество">
                <input type="text" v-model="email" name="email" class="ui-input w-full" placeholder="E-mail">
                <input type="text" v-model="phone" name="phone" class="ui-input w-full" placeholder="Номер телефона">
                <Button @click="confirmDetails()" v-if="selectedCity && selectedPVZ && lastname && firstname && parentname && lastname && email && phone">Подтвердить</Button>
            </div>
            <div class="order-block bg-white mt-4 p-4 rounded-xl flex flex-col gap-4">
                <Button @click="go2pay()" v-if="data.stage == 2 && !orderPayed">Оплатить {{ data.cart[0].price }} ₽</Button>
                <span v-if="orderPayed">Заказ оплачен!</span>
                <Button variant="red">Отменить заказ</Button>
            </div>
        </div>
        
        
    </div>
    <NavigationComponent></NavigationComponent>
</template>
<script>
import PageBadge from "@/components/PageBadge.vue";

import NavigationComponent from "@/components/Navigation.vue";
import CityInput from "@/components/Delivery/CityInput.vue";
import PVZInput from "@/components/Delivery/PVZInput.vue";
import Button from "@/components/Button.vue";


export default {
    name : "OrderView",
    components : {
        NavigationComponent,PageBadge,CityInput,PVZInput,Button
    },
    data(){
        return {
            stage : 1,
            showPVZ : false,
            selectedPVZ : undefined,
            selectedCity : undefined,
            waitingPayment: false,
            orderPayed : false,
            waitingInterval : false,
            lastname: "",
            firstname : "",
            parentname : "",
            email : "",
            phone : "",
        }
    },
    props : {
        data : {
            type : Object
        }
    },
    methods : {
        setupCity(cityObject){
            this.selectedCity = 0;
            setTimeout(() => {
                this.selectedCity = cityObject.code;    
            }, 200);
            
            
            
        },
        setupPVZ(pvz){
            this.selectedPVZ = pvz;
        },
        async confirmDetails(){
            let setupOrder = await this.$api.post(`order/${this.data.order_num}/setup`,{
                lastname : this.lastname,
                firstname : this.firstname,
                parentname : this.parentname,
                phone : this.phone,
                email : this.email,
                city : this.selectedCity,
                pvz : this.selectedPVZ
            })
            if(setupOrder.status == "updated"){
                alert('Данные отправлены продавцу. Ожидайте подтверждения заказчки')
                this.$router.push('/orders/' + this.data.order_num);
            }
            
        },
        async go2pay(){

            
            
            

            let data = await this.$api.post('order/'+this.data.order_num+'/go2pay');
            if(data.PaymentURL){
                let a = document.createElement("a");
                a.href = data.PaymentURL;
                a.target = "_blank"
                a.innerHTML = "1";
                document.body.appendChild(a);
                a.click();
                
                this.$gram.WebApp.openLink(data.PaymentURL)

                this.showWaitingModal();
            }
            
        },
        showWaitingModal(){
            this.waitingPayment = true;
            setTimeout(() => {
                this.$refs.waiting.style.top = '0'     
            }, 100);
            this.waitingInterval = setInterval(async ()=>{
                let checkPayment = await this.$api.get(`order/${this.data.order_num}/check/pay`);
                if(checkPayment.status == '200'){
                    if(checkPayment.paystatus == 'AUTHORIZED'){
                        this.hideWaitingModal();
                        this.orderPayed = true;
                        clearInterval(this.waitingInterval);
                    }
                }
            },5000)
        },
        hideWaitingModal(){
            this.$refs.waiting.style.top = '100%'     
            
            setTimeout(() => {
                this.waitingPayment = true;    
            }, 200);
        }
    },
    mounted(){
     
        
    },
}
</script>
<style scoped>
.waiting-payment{
    height: 98%;
    transition: .2s linear 0s;
    width: 100%;
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 300;
    align-items: center;

    background-image: linear-gradient(45deg, #000,#02AEBA);
    border-radius: 50px;
    color: #fff;
    top: 100%;
    padding: 30px;
    position: fixed;
    z-index: 100;
    bottom: 0;
}

.time-tip{
    font-size: 12px;
    color: #959595;
}
.cart-product{
    display: flex;
    gap: 10px;
    width: 100%;
}
.cart-product-info{
    display: flex;
    flex-direction: column;
}
.cart-product-name{
    max-width: 100%;
    
}
.cart-product-price{
    color: #0181A8;
    font-weight: 500;
    font-size: 20px;
}
.card-product-image{
    height: 90px;
    width: 90px;
    flex: none;
    border-radius: 10px;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}

.step {
  
  padding: 10px;
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  
  background-color: cream;
}

.v-stepper {
  position: relative;
/*   visibility: visible; */
}


/* regular step */
.step .circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 20px;    /* +6 for border */
  height: 20px;
  display: inline-block;
}

.step .line {
    top: 20px;
    left: 9px;
/*   height: 120px; */
  height: 100%;
    
    position: absolute;
    border-left: 3px solid gray;
}

.step.completed .circle {
  visibility: visible;
  background-color: rgb(6,150,215);
  border-color: rgb(6,150,215);
}

.step.completed .line {
  border-left: 3px solid rgb(6,150,215);
}

.step.active .circle {
visibility: visible;
  border-color: rgb(6,150,215);
}

.step.empty .circle {
    visibility: hidden;
}

.step.empty .line {
/*     visibility: hidden; */
/*   height: 150%; */
  top: 0;
  height: 150%;
}


.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1; /* behind the circle to completely hide */
}

.content {
  margin-left: 20px;
  display: inline-block;
  font-size: 15px;
  color: #4F4F4F66;
}
.completed .content{
    color: unset;
}
</style>