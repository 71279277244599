<template>
    <div class="page">
        <PageBadge  :text="'Мои заказы'"></PageBadge>
        
            <div class="order" v-show="list.length > 0" v-for="order in list" :key="order._id" @click="$router.push('/orders/' + order.order_num)">
                <img :src="`https://cdn.tagito.store/products/${order.product.photos[0]}.png`" class="photo">
                <div class="order-info">
                    <div class="product">
                        <span>{{order.product.name}}</span>
                        <span class="price">{{order.cart[0].price}} ₽</span>
                    </div>
                    <div class="status">
                        {{ order.status }}
                    </div>
                </div>
                <div class="click">
                    <img src="@/assets/icons/order-continue.svg" alt="">
                </div>
            </div>
            <div class="container" v-show="list.length == 0">
                <span>{{$t("orders.notfound")}}</span>
            </div>
        </div>
        
    
    <NavigationComponent></NavigationComponent>
</template>
<script>
// import Button from "@/components/Button.vue";
import NavigationComponent from "@/components/Navigation.vue";
import PageBadge from '@/components/PageBadge.vue'
export default {
    name : "OrdersList",
    components: {NavigationComponent, PageBadge},
    async mounted(){
        let orders = await this.$api.get('orders/my');
        this.list = orders.orders;
    },
    data(){
        return {
            list : [],
        }
    }
}
</script>
<style scoped>
.order{
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
}
.photo{
    width: 100px;
    height: 120px;
    flex: none;
    object-fit: cover;
    border-radius: 10px;
}
.order-info{
    padding: 10px;
    display: flex;
    height: 100%;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
}
.product{
    display: flex;
    flex-direction: column;
}
.price{
    background: linear-gradient(90deg, #0181A8 0%, #02AEBA 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 18px;
    font-weight: 600;
}
.status{
    font-size: 14px;
    background-color: rgba(2, 174, 186, 1);
    width: max-content;
    padding: 5px 10px;
    border-radius: 100px;
    color: #fff;
}
</style>