<script>
import Button from "@/components/Button.vue";
import NavigationComponent from "@/components/Navigation.vue";
import PageBadge from "@/components/PageBadge.vue";
import {initCloudStorage} from "@telegram-apps/sdk";

export default {
  name: "ProfilePage",
  components: {NavigationComponent, PageBadge, Button},
  data(){
    return {
      avatarLoaded : false,
      cloud : initCloudStorage()
    }
  },
  methods : {
    select(locale){
      this.cloud.set('locale',locale);
      this.$i18n.locale = locale;
      this.$router.push('/home')
    }
  },

  async mounted() {
    
    
    

  }
}
</script>

<template>
    <PageBadge text="Выберите язык / Choose language" variant="header"></PageBadge>
  <div class="page">
    
    <div class="container langs">
        <Button @click="select('ru')">Русский</Button>
        <Button @click="select('en')">English</Button>
        
    </div>
  </div>
  <NavigationComponent></NavigationComponent>
</template>

<style scoped>
  .name{
    font-weight: 500;
    font-size: 18px;
    color: #2D2D2D;
  }
  .page{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    gap: 10px;
  }
  .fake-avatar{
    height: 125px;
    width: 125px;
    border-radius: 50%;
    position: relative;
    background: #24b9c5;
    color: #fff;
    font-size: calc(5vh);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .langs{
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .avatar{
    border-radius: 50%;
    position: absolute;
  }

  .menu-item-icon{
    height: 35px;
    width: 35px;
    background: linear-gradient(90deg, #0181A8 0%, #02AEBA 100%);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-item{
    background: #fff;
    display: flex;
    box-shadow: 0px 0px 10px 0px #0000000D;
    font-size: 15px;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 15px;
  }
</style>