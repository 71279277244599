<script>
import PageBadge from "@/components/PageBadge.vue";
import Navigation from "@/components/Navigation.vue";
import ButtonUI from "@/components/Button.vue";
import FileUploader from "@/components/FileUploader.vue";
import TextareaComponent from "@/components/Textarea.vue";
import ProPlusForm from "@/components/ProPlus/ProPlusForm.vue";
let axios = require("axios");
export default {
  name: "EditProduct",
  components: {TextareaComponent, FileUploader, ButtonUI, Navigation, PageBadge, ProPlusForm},
  data(){
    return {
      category : null,
      loading : true,
      btnLoading : false,
      proformopenned : false,
      removedBanners : [],
      probanners : [],
      saved_pros : [],
      name : "",
      price : "",
      description : "",
      photos :  [],
      removedFiles : []
    }
  },
  methods:{
    hideMobileKeyboard(ev){
      ev.target.blur();
    },
    savePro(banners){
      this.probanners = banners;
      this.proformopenned = false;
      
    },
    toFile(ev){
      this.photos.push({f : ev[0], s : URL.createObjectURL(ev[0])});
    },
    async create(){
      let name = this.name;
      let price = this.price;
      let description = this.description;
      let files = this.photos;

      if(name === ""){
        return alert(this.$t("product.creation.error_name"))
      }

      if(description.length > 1500){
        return alert(this.$t("product.creation.error_length"))
      }

      var formData = new FormData();
      formData.append("name",name);
      formData.append("price",price);
      formData.append("description",description);
      formData.append("removed",this.removedFiles);
      console.log(JSON.stringify(this.removedBanners));
      
      formData.append("removed_banners",JSON.stringify(this.removedBanners));
      for(let file of files){
        
        if(file.f != null){
          
          formData.append("photos", file.f);
        } 
      }


      console.log(`Текущие баннеры`,this.probanners);
      console.log(`Удаленые баннеры`,this.removedBanners);
      let uploadBanners = this.probanners;
      for(let removed of this.removedBanners){
        if(uploadBanners.indexOf(removed) > -1){
          uploadBanners.splice(uploadBanners.indexOf(removed),1);
        }
      }
      console.log(this.probanners)
      for(let i in this.probanners){
        if(this.probanners[i].source != null){
          if(!formData.has(`pro_banner_${i}_size`)){
            formData.append(`pro_banner_${i}_size`,this.probanners[i].size)
            formData.append(`pro_banner_${i}_file`,this.probanners[i].source)
          }
        }
      }
      console.log(`Новые банеры`,uploadBanners);
      
      

      this.btnLoading = true;
      let data = await axios.post(this.$api.ssl + this.$api.baseURL + '/products/' + this.$route.params.id + '/edit', formData, {
        headers: {
          'Authorization' : 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      })
      if(data.data.status == 200){
        alert(this.$t("product.creation.success_save"));
        this.$router.go(-1);
        // this.$router.push('/shops/view/' + this.$route.query.shop + '?from=' + this.$route.query.from);
      }
      else{
        alert(data.data.message)
      }
      this.btnLoading = false;
    },
    removePhoto(photo){
      let f = this.photos[this.photos.indexOf(photo)]
      if(f.f == null){
        this.removedFiles.push(f.s.split('products/')[1])
      }
      console.log(this.photos)
      this.photos.splice(this.photos.indexOf(photo),1);
    },
    removeBanner(banner){
      this.removedBanners.push(banner)
    }
  },
  async mounted(){
    let product = await this.$api.get('products/' + this.$route.params.id)
    this.name = product.name;
    this.description = product.description;
    this.start_banners = product.probanners;
    this.price = product.price;
    for(let photo of product.photos){
      this.photos.push({
        f : null,
        s : `${this.$cdn}products/${photo}.png`
      })
    }
    for(let banner in product.probanners){
      this.probanners.push({id: banner, size : product.probanners[banner].size, source : null, image : `${this.$cdn}pro/${product.probanners[banner].file}.png`});
    }
    
    console.log(this.photos)
    this.loading = false;
  }
}
</script>

<template>
  <ProPlusForm v-if="proformopenned" @cancel="proformopenned = false" @save="savePro" :edit="true" :data="probanners" @removed="removeBanner"></ProPlusForm>
  <PageBadge back="1" v-if="!proformopenned" variant="header" :text="$t('product.creation.edit_product')"></PageBadge>
  <div class="page" v-if="!proformopenned && !loading" @click="void(1)">

    <div class="container" @click="void(1)">
      <form action="?" class=" flex flex-col gap-2 " @submit.prevent>


        <label for="">
          <span class="label-input">{{$t("product.creation.form.name.title")}}*</span>
          <input v-model="name" type="text" class="ui-input w-full"  @keyup.enter="hideMobileKeyboard" :placeholder="$t('product.creation.form.name.placeholder')" name="name">
        </label>
        <label for="">
          <span class="label-input">{{$t("product.creation.form.description.title")}}</span>
          <TextareaComponent :value="description" @enter="e => description = e" maxlength="1500" :placeholder='$t("product.creation.form.description.placeholder")'></TextareaComponent>
        </label>
        <span class="label-input">{{$t("product.creation.form.photo.title")}}*</span>
        <div class="images">
          <div class="image" v-for="photo in photos" :key="photo">
            <img :src="photo.s" alt="">
            <div class="remove" @click="removePhoto(photo)">
              <img src="@/assets/icons/remove_photo.svg" alt="">
            </div>
          </div>
          <FileUploader v-if="photos.length < 7" nopreview @file="toFile"></FileUploader>
        </div>
        <label for="">
          <span class="label-input">{{$t("product.creation.form.price.title")}}*</span>
          <input v-model="price" type="text" inputmode="numeric" class="ui-input w-full"  @keyup.enter="hideMobileKeyboard" :placeholder="$t('product.creation.form.price.title')" name="name">
        </label>

        <ButtonUI variant="dark" class="mt-2 w-full" @click="proformopenned = true" >Pro+</ButtonUI>
        <ButtonUI class="mt-2" @click="create()" :loading="btnLoading">{{$t("system.save")}}</ButtonUI>
      </form>



    </div>
    <Navigation></Navigation>
  </div>
</template>

<style scoped>
.label-input{
  font-size: 15px;
}
label{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.banner{
  background: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  height: 100px;
}
.banner img{
  opacity: 0.5;
}
.banner-text{
  font-size: 13px;
  color: #4F4F4F;
}
.image{
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 100px;
  justify-content: center;
  border-radius: 5px;
  font-size: 15px;
}
.image img{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Обрезка изображения, сохраняя его центр */
  object-position: center;
}
.remove{
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: calc(10px);
}
.remove svg{
  width: 30px;
  height: 30px;
}
.images{
  display: flex;
  gap: 10px;
}
</style>