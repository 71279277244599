<template>
    <div v-if="banners && banners.length > 0">
        <swiper :modules="modules" :autoplay="true">
        <swiper-slide v-for="banner of banners" :key="banner" >
            <div @click="openAd(banner)">
                <img :src="`${$cdn}ads/${banner.banner}.png`" class="ad-image" alt="">
            </div>
        </swiper-slide>
      </swiper>
    </div>
    
</template>
<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import {Autoplay} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/autoplay';
export default {
    name : "AdBanner",
    components: {
      Swiper,
      SwiperSlide,
    },
    data(){
        return {
            banners : []
        }
    },
    async mounted(){
            
            
            this.banners = await this.$api.get('ads/get');    
        
            
            
    },
    methods: {
        async openAd(banner){
            if(banner.type == "product"){
                let product = await this.$api.get('products/' + banner.data)
                if(product.status != 400){
                    this.$router.push('/products/view/' + banner.data);
                }
            }
            else if(banner.type=="shop"){
                let shop = await this.$api.get('shops/' + banner.data)
                if(shop.status != 400){
                    this.$router.push('/shops/view/' + banner.data);
                }
            }
        }
    },
    setup() {
    return {
      
      modules: [Autoplay],
    };
  },
}
</script>
<style scoped>
    div img{
        object-fit: contain;
        
    }
    div{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 2px;
    }
    .ad-image{
        border-radius: 30px;
    }
</style>