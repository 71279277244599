<template>
    <div class="pro-form">
        <PageBadge variant="header" text="Pro+"></PageBadge>
        <div class="container flex flex-col items-center">
            <input type="file" name="" id="upl" style="display: none;" @input="uploadFile">
        <label class="block" for="upl" v-if="banners.length < 6">
            <img src="@/assets/icons/upload.svg" alt="">
        </label>
        <div class="select-size"  v-if="selectSize">
            
            <div class="sizes">
                <span style="font-size: 20px; color: #fff; margin: 10px 0">Выберите размер блока</span>
                <div class="block big" @click="setSize('big')">
                    <span>Высокий</span>
                    <span>350px/300px</span>
                </div>
                <div class="block medium" @click="setSize('medium')">
                    <span>Средний</span>
                    <span>350px/200px</span>    
                </div>
                <div class="block small" @click="setSize('small')">
                    <span>Маленький</span>
                    <span>350px/100px</span>
                </div>
                
                
            </div>
        </div>
        <div class="mt-2">
            <div :class="`preblock ${banner.size}`" v-for="banner of banners" :key="banner">
                <img src="@/assets/icons/remove_photo.svg" class="remove" @click="removeBanner(banner)">
                <img :src="(banner.image) ? banner.image : banner.s3" class="blimg" alt="">
            </div>
        </div>
        
        <Button class="w-full mt-4 mb-4"  @click="$emit('save',banners)">Опубликовать</Button>
        <Button class="w-full mb-4" variant="red" @click="$emit('cancel')">Отменить</Button>
        
        </div>
        
    </div>
</template>
<script>
import Button from '../Button.vue';
import PageBadge from '../PageBadge.vue';

export default {
    name : "ProPlus",
    components : {
        Button,PageBadge
    },
    props : {
        data : {
            type : Array
        },
        edit : {
            type : Boolean,
        }
    },
    data(){
        return {
            selectSize: false,
            currentFileOnSize : false,
            banners : [...this.data],
        }
    },
    mounted(){
    
    },

    methods : {
        setSize(size){
            this.banners.push({id: this.banners.length, size : size, source : this.currentFileOnSize, image : URL.createObjectURL(this.currentFileOnSize)});
            this.selectSize = false;
        },
        async uploadFile(ev){
            if(ev.target.files.length > 0){
                
                
                let comporessed = await this.$compress(ev.target.files[0]);
                
                
                
                this.currentFileOnSize = comporessed
                this.selectSize = true;
            }
        },
        removeBanner(banner){
            this.banners.splice(this.banners.indexOf(banner),1);
            this.$emit('removed',banner);
        }
    },
}
</script>
<style scoped>
.block{
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
.preblock{
    width: 350px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}
.sizes{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    margin-top: 40px;
    backdrop-filter: blur(10px);
    background: rgba(255,255,255,0.3);
}

.blimg{
    height: inherit;
    width: inherit;
    object-fit: cover;
    
}
.pro-form{
    background-color: #F2F2F2;
    min-height: 100;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    
    z-index: 10;
}

.select-size{
    position: fixed;
    z-index: 11;
    height: 100vh;
    width: 100%;
    top: 0;
    background-color: rgba(0,0,0,0.4);
}

.small{
    height: 80px;
}
.medium{
    height: 100px;
}
.big{
    height: 160px;
}
.sizes .block{
    background: unset;
    border: 1px  #fff dashed;
    position: relative;
    color: #fff;
}
.sizes .block span:nth-child(1){
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.sizes .block span:nth-child(2){
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.remove{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>