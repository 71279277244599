<script>
import NavigationComponent from "@/components/Navigation.vue";
import {initPopup, initUtils} from '@telegram-apps/sdk';
import {Pagination} from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import ButtonUI from "@/components/Button.vue";
import ProductBrand from "@/components/ProPlus/ProductBrand.vue";


export default {
  name: "ProductView",
  components: {ButtonUI, NavigationComponent, Swiper, SwiperSlide, ProductBrand},
  data(){
    return {
      utils : initUtils(),
      popup : initPopup(),
      proPlus : {
        blocks : []
      },
      seller : {},
      $swiper : null,
      shop : {},
      fullscreen_photo : false,
      prodData : {},
      loading: true,
    }
  },
  async mounted(){
    
    window.scroll(0,0)
    let product = await this.$api.get('products/' + this.$route.params.id)
    if(!product.name){
      this.$router.push('/home');

    }
    else{
      this.prodData = product;
      this.proPlus.blocks = product.probanners;
      this.seller = await this.$api.get('users/' + product.seller);
      
      this.shop = await this.$api.get('shops/view/' + product.shop);
    
    }
    this.loading = false;

    this.paginationFull = {
        clickable: true,
        renderBullet: (index,className) => {
          return `<span class="photo-preview ${className}" index="${index}'"><img class="photo-preview-src" src="${this.$cdn}products/${this.prodData.photos[index]}.png"></span>`;
        },
    }
  },
  methods : {
    async removeProduct(){
      this.popup.open({
        title: this.$t("system.confirm_action"),
        message: this.$t("product.confirmation_of_delete"),
        buttons: [{ id: 'cancel', type: 'default', text: this.$t("system.cancel") },{ id: 'remove', type: 'destructive', text: this.$t("product.remove_product") }],
      }).then( async buttonId => {

                if(buttonId === "remove"){
                  let product = await this.$api.post('products/remove/' + this.$route.params.id)
                  if(product.status === 200){
                    this.$router.push('/home');
                    alert(this.$t("product.success_deletion"))
                  }
                }
          });
    },
    editProduct(){
      this.$router.push('/products/edit/' + this.$route.params.id);
    },
    getContact(){
      this.utils.openTelegramLink('https://t.me/' + this.seller.username);
    },
    async createOrder(){
      // API CREATE ORDER 
      let order = await this.$api.post('orders/create',{
        cart : [this.prodData]
      });
      if(order.id){
        this.$router.push('/orders/' + order.id);
      }
      
    },
    sharePage(){
      console.log("share")
      this.utils.shareURL('t.me/Tagito_bot/Tagito?startapp=place_product' + this.$route.params.id, 'Посмотри, классный товар нашел на Tagito!');
    },
    openShop(){
      this.$router.push('/shops/view/' + this.shop._id + '?from=' + this.$route.path);
    },
    getRef(swiper){
      this.$swiper = swiper;
      
    }
  },
  setup() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '" index="'+index+'"></span>';
        },
      },
      
      modules: [Pagination],
    };
  },

}
</script>

<template>
  <div class="loading-block" v-if="loading">
    <div class="logotype">
      <video  playsinline autoplay width="100" muted  loop>
        <source src="@/assets/loading.webm" type="video/webm">
      </video>
    </div>
  </div>
  <div class="product-view page" v-if="!loading">

    <div class="image-library" v-show="fullscreen_photo">
      <div class="close" @click="fullscreen_photo = false">
        <img src="@/assets/icons/close.svg">
      </div>
      <swiper
          :pagination="paginationFull" :modules="modules"
          @swiper="getRef"
      >
        <swiper-slide v-for="photo of prodData.photos" :key="photo" @click="console.log"><div class="product-image-source preview-photo-element" :style="`background-image: url(${$cdn}products/${photo}.png)`"></div><div class="product-image-blured" :style="`background-image: url(${$cdn}products/${photo}.png)`"></div></swiper-slide>
      </swiper>
    </div>

    <div class="product-header">
      <div class="mini-button" @click="sharePage()">
        <img src="@/assets/icons/share.svg" alt="">
      </div>

    </div>
    <div class="product-image">
      <swiper
          :pagination="pagination" :modules="modules"
      >
        <swiper-slide v-for="photo of prodData.photos" :key="photo" @click="fullscreen_photo = true; $swiper.slideTo(prodData.photos.indexOf(photo))"><div class="product-image-source" :style="`background-image: url(${$cdn}products/${photo}.png)`"></div><div class="product-image-blured" :style="`background-image: url(${$cdn}products/${photo}.png)`"></div></swiper-slide>
      </swiper>
    </div>
    <div class="container">
      <div class="info">
        <span class="price">{{$fixPrice(prodData.price)}} ₽</span>
        <span class="name">{{ prodData.name }}</span>
      </div>
      <div class="actions">
        <ButtonUI @click="getContact" v-if="prodData._id != '67bd2d8c3840aaf9421f80e4'">{{$t('product.contact')}}</ButtonUI>
        <ButtonUI @click="createOrder" v-else-if="prodData.price != 0">Заказать</ButtonUI>
      </div>
      <div class="divider"  v-if="seller.id === $store.state.userInfo.id"></div>
      <div class="actions" v-if="seller.id === $store.state.userInfo.id">
        <ButtonUI @click="editProduct()">{{$t('product.edit_product')}}</ButtonUI>
        <ButtonUI variant="minimal-red" @click="removeProduct()">{{$t('product.remove_product')}}</ButtonUI>
      </div>
      <div class="divider"></div>
      <div class="shopCard">
        <div class="shopInfo">
          <div class="shopAvatar" :style="`background-image: url(${$cdn}shops/${shop.logo}.png)`"></div>
          <span class="shopName">{{shop.name}}</span>
        </div>
        <div class="shopAction">
          <span @click="openShop">{{$t('product.see_all')}}</span>
        </div>
      </div>
      <div class="divider"></div>
      <div class="info-block" v-if="prodData.description">
        <h2>{{$t('product.description')}}</h2>
        <p>{{prodData.description}}</p>
      </div>
      <ProductBrand :data="proPlus" v-if="proPlus.blocks.length > 0"></ProductBrand>

    </div>
  </div>
  <NavigationComponent>
  </NavigationComponent>
</template>

<style scoped>
.loading-block{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column; gap: 20px;
  width: 100%;
}
.logotype{
  border-radius: 15px;
  overflow: hidden;
  height: max-content;
  width: max-content;
}
video{
  width: 110px;
  height: 110px;
}
.product-view{
  width: 100%;
  min-height: 100vh;
  background: #fff;
}
.shopCard{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  background: #F2F2F2;
}
.shopInfo{
  display: flex;
  align-items: center;
  gap: 10px;
}
.shopAvatar{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.shopAction span{
  color: #0181A8;
  font-size: 13px;
}
h2{
  font-size: 20px;
  color: #4F4F4F;
  font-weight: 500;
}
.info-block p{
  color: #4F4F4FB2;
  font-size: 13px;
  white-space: pre-wrap;
  line-height: 15.85px;
}
.shopName{
  font-size: 18px;
  color: #4F4F4F;
  font-weight: 500;
}
.container{
  display: flex;

  flex-direction: column;
  gap: 10px;
}
.info{
  display: flex;
  flex-direction: column;
}
.actions{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.product-header{
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  padding: 10px;
  z-index: 2;
  justify-content: space-between;
}
.page{
  padding-top: 0px;
  height: 100% !important;
}
.name{
  font-size: 18px;
  color: #4F4F4F;
  font-weight: 500;
}
.product-image{
  
  width: 100%;
  height: 400px;
  position: relative;
}
.product-image .swiper{
  align-items: center;
  display: flex;
  flex-direction: column
}
.swiper-pagination{
  position: relative;
  bottom: 10px;
}
.product-image-source{
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.product-image-blured{
  height: 100%;
  top: 0;
  z-index: 1;
  filter: blur(2px) brightness(0.7);
  
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.price{
  font-size: 24px;
  background: linear-gradient(90deg, #0181A8 0%, #02AEBA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}
</style>