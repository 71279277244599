<script>
export default {
  name: "FileUploader",
  props:{
    text:String,
    nopreview:Boolean,
    customPreview:String,
    circle : Boolean
  },
  data(){
    return {
      files : [],
      imageSrc : null
    }
  },
  methods : {
    selectFile(ev){
      this.$emit('file',ev.target.files)
      if(!this.nopreview){
        
        if(this.customPreview){
          this.imageSrc = this.customPreview;
          
        }
        else{
          this.imageSrc = URL.createObjectURL(ev.target.files[0]);
          
        }

      }

    }
  },
  mounted() {

  }
}
</script>

<template>
  <span>{{text}}</span>
  <label :class="(!circle) ? 'custom-file-input' : 'custom-file-input circle'">
    <slot class="file-preview" v-if="nopreview"></slot>
    <img :src="imageSrc" v-if="imageSrc" class="file-preview" alt="">
    <input type="file" name="" id="" accept="image/jpeg, image/png" ref="file" @input="selectFile">
    <img class="icon" v-if="!circle" src="@/assets/icons/upload.svg" alt="">
  </label>

</template>

<style scoped>
  input{
    display: none;
  }
  .icon{
    max-width: 70px;
    padding: 5px;
    position: relative;
    z-index: 2;
    opacity: 0.7;
    border-radius: 50%;
    backdrop-filter: blur(10px);
  }
  .types{
    font-size: 12px;
    color: #4F4F4F99;
  }
  .preview{
    height: 100%;
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
  }
  .previewer{
    background: rgba(255, 255, 255, 0.62);
    padding: 20px;
    border-radius: 10px;
  }
  .preview{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
  .custom-file-input{
    padding: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    width: 100px;
    height: 100px;
    justify-content: center;
    border-radius: 5px;
    font-size: 15px;
  }
  .circle{
    border-radius: 50% !important;
  }
</style>