<script>
export default {
  name: "ShopName",
  props : {
    skeleton : {
      type : Boolean
    },
    text : {
      type : String,
    }
  }
}
</script>

<template>
  <span :class="(skeleton) ? 'a-skeleton shop-include' : 'shop-include avatar'">{{text}}</span>
</template>

<style scoped>
.shop-include{
  height: 22px;
  text-align: center;
  font-size: 18px;
  width: 300px;
  font-weight: 500;
}
.a-skeleton{
  border-radius: 10px;
}

</style>