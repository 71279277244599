<script>
import PageBadge from "@/components/PageBadge.vue";
import Navigation from "@/components/Navigation.vue";
import ButtonUI from "@/components/Button.vue";
import FileUploader from "@/components/FileUploader.vue";
import TextareaComponent from "@/components/Textarea.vue";
import { Cropper,CircleStencil,Preview } from 'vue-advanced-cropper'
import AddressInput from "@/components/AddressInput.vue";

import 'vue-advanced-cropper/dist/style.css';
let axios = require("axios");
export default {
  name: "CreateShop",
  // eslint-disable-next-line vue/no-unused-components
  components: {TextareaComponent, FileUploader, ButtonUI, Navigation, PageBadge, Cropper,CircleStencil,Preview,AddressInput},
  data(){
    return {
      resizerOpened : false,
      category : null,
      resizeImage : "",
      loading : null,
      previewLink : "",
      name : "",
      slogan : "",
      description : "",
      address : "",
      result: {
        coordinates: null,
        image: null
      }
    }
  },
  methods:{
    onChangeSizer({ coordinates, image }) {
      console.log(123)
      this.result = {
        coordinates,
        image
      };

    },
    hideMobileKeyboard(ev){
      ev.target.blur();
    },
    toFile(ev){
      this.resizeImage = URL.createObjectURL(ev[0])

      this.resizerOpened = true;
      // this.file = ev[0];
    },
    cancelResize(){
      this.resizerOpened = false;

    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0){
        byteString = atob(dataURI.split(',')[1]);
      }
      else {
        byteString = unescape(dataURI.split(',')[1]);
      }
      // separate out the mime component


      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {type:"image/png"});
    },
    confirmResize(){
      this.result = this.$refs.cropper.getResult();

      this.result.canvas.toBlob(async blob => {
        this.previewLink = URL.createObjectURL(blob)
      })

      this.resizerOpened = false;
    },
    async create(){
      let name = this.name;
      let slogan = this.slogan;
      let description = this.description;
      let city = this.address;
      let category = this.$route.query.cat;

      if(name === ""){
        return alert(this.$t('shop.creation.error_name'))
      }
      if(slogan.length > 100){
        return alert(this.$t('shop.creation.error_slogan'))
      }
      if(description.length > 300){
        return alert(this.$t('shop.creation.error_description'))
      }
      console.log(this.result)
      if (this.result.canvas) {
        let canvas = this.result.canvas
        canvas.toBlob(async blob => {
          this.loading = true;
          var formData = new FormData();
          formData.append("name",name);
          formData.append("slogan",slogan);
          formData.append("description",description);
          formData.append("city",city);
          formData.append("category",category)
          let compress = await this.$compress(blob);
          formData.append('logo', compress);
          let data = await axios.post(this.$api.ssl + this.$api.baseURL + '/shops/create', formData, {
            headers: {
              'Authorization' : 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'multipart/form-data'
            }
          })
          if(data.data.status == 200){
            alert(this.$t('shop.creation.success_creation'));
            this.loading = false;
            if(this.$store.state.userShops){
              this.$store.state.userShops.push(data.data.shop)
            }
            else{
              this.$store.state.userShops = [data.data.shop]
            }
            this.$router.push('/shops/manage')
          }
          else{
            alert(data.data.message)
          }
          // Perhaps you should add the setting appropriate file format here
        }, 'image/jpeg');
      }
      else{
        alert(this.$t('error_logo'))
      }


    }

  },
  mounted(){
    if(this.$route.query.cat){
      this.category = this.$route.query.category;
    }
  }
}
</script>

<template>
  <PageBadge back="/shops/create/first"  variant="header" :text="$t('shop.creation.title')"></PageBadge>
  <div class="resizer page" v-show="resizerOpened">
    <div class="resizer-image-container">
      <cropper
          :src="resizeImage"
          :stencil-component="$options.components.CircleStencil"
          :aspect-ratio="1"
          ref="cropper"
          @change="onChangeSizer"
      />
    </div>
    <div class="container previewer" >
      <img src="" alt="" class="preview-photo">
      <span class="preview-text">{{$t('shop.creation.preview')}}</span>
      <div class="previewSteps">
        <div class="previewStep first-preview">
          <preview :width="70"
                   :height="70"
                   :image="result.image"
                   :coordinates="result.coordinates"></preview>
          <span class="preview-shop-name">{{$t('shop.creation.myshop')}}</span>
          <span class="preview-shop-slogan">{{$t('shop.creation.slogan')}}</span>
        </div>
      </div>
    </div>
    <div class="resizer-actions container">
      <ButtonUI @click="confirmResize">{{$t('system.confirm')}}</ButtonUI>
      <ButtonUI variant="red" @click="cancelResize">{{$t('system.cancel')}}</ButtonUI>
    </div>

  </div>
  <div class="page" @click="void(1)">

    <div class="container" @click="void(1)">
      <form action="?" class=" flex flex-col gap-2 " @submit.prevent @click="void(1)">
        <label for="">
          <span class="label-input">{{$t('shop.creation.form.name.title')}}*</span>
          <input v-model="name" type="text" class="ui-input w-full"  @keyup.enter="hideMobileKeyboard" :placeholder="$t('shop.creation.form.name.placeholder')" name="name">
        </label>
        <label for="">
          <span class="label-input">{{$t('shop.creation.form.slogan.title')}}</span>
          <TextareaComponent @enter="e => slogan = e" maxlength="100" :placeholder="$t('shop.creation.form.slogan.placeholder')"></TextareaComponent>
        </label>
        <label for="">
          <span class="label-input">{{$t('shop.creation.form.description.title')}}</span>
          <TextareaComponent @enter="e => description = e" maxlength="300" :placeholder="$t('shop.creation.form.description.placeholder')"></TextareaComponent>
        </label>
        <label for="">
          <span class="label-input">{{$t('shop.creation.form.address.title')}}</span>
          <AddressInput :value="address" @enter="e => address = e" maxlength="300" :placeholder="$t('shop.creation.form.description.placeholder')"></AddressInput>
        </label>

        <FileUploader :nopreview="true" :text="$t('shop.creation.form.logo.title')" @file="toFile">
          <slot>
            <img :src="previewLink" class="file-preview" alt="">
          </slot>
        </FileUploader>

        <label for="" class="w-full">
          <span class="label-input">{{ $t('pro.name') }}</span>
          <span class="banner">
            <img src="@/assets/icons/upload.svg" alt="">
            <span class="banner-text">{{$t('system.indev')}}</span>
          </span>
        </label>
        <ButtonUI class="mt-2" @click="create()" :loading="loading">{{$t('shop.creation.action')}}</ButtonUI>
      </form>



    </div>
    <Navigation></Navigation>
  </div>
</template>

<style scoped>
  .label-input{
    font-size: 15px;
  }
  label{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .banner{
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 350px;
    height: 100px;
  }
  .banner img{
    opacity: 0.5;
  }
  .banner-text{
    font-size: 13px;
    color: #4F4F4F;
  }
  .resizer{
    background: #fff;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
  }
  .resizer-image-container{
    height: 60%;
    position: relative;
  }
</style>