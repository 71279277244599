<template>
    <div class="page">
        <h1>seller</h1>
    </div>
</template>
<script>
export default {
    name : "OrderView",
    data(){
        return {
            stage : 0,
        }
    },
    methods : {

    }
}
</script>
<style scoped></style>