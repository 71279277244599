<script>
import PageBadge from "@/components/PageBadge.vue";
import NavigationComponent from "@/components/Navigation.vue";
import ProductInShop from "@/components/Products/ProductInShop.vue";
import Button from "@/components/Button.vue";

export default {
  name: "ViewCategory",
  data(){
    return {
      loadingProds : true,
      failToGet : false,
      products : [],
    }
  },
  async mounted() {
      this.loadingProds = true
      let products = await this.$api.get('categories/' + this.$route.params.id +'/products?date=1');
      console.log(products);
      if(!products.stack && products.length > 0){
        this.$store.state.storeCache.productOnMain = products;
        
        for(let product of products){
          if(product._id == "67bd2d8c3840aaf9421f80e4"){
            products.splice(products.indexOf(product),1);
          }
        }
        this.products = products;
        
      }
      else{
        this.failToGet = true;
      }
      this.loadingProds = false;
      

  },
  components: {ProductInShop, NavigationComponent, PageBadge, Button }
}
</script>

<template>
  <div class="page" v-if="!failToGet">
    <PageBadge :text="$route.query.title" back="1"></PageBadge>
    <div class="product-container" v-if="!loadingProds">
      <ProductInShop :data="product" :key="product" v-for="product of products"></ProductInShop>
    </div>
    <div class="product-container" v-if="loadingProds">
      <div class="fake-product a-skeleton" v-for="i in 10" :key="i"></div>
    </div>
  </div>
  <div class="tryagain" v-if="failToGet">
    <h1>{{$t('error_page.enter_laiter')}}</h1>
    <span>{{$t('error_page.message')}}</span>
    <Button @click="$router.go(-1)">{{ $t("error_page.back") }}</Button>
  </div>

  <NavigationComponent></NavigationComponent>
</template>

<style scoped>
.fake-product{
  height: 200px;
  border-radius: 20px;
  width: 120px;
}

h1{
    font-weight: 600;
    font-size: 18px;
  }
.tryagain{
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
</style>