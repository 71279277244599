<template>
    <div class="loading-block">
      <div class="logotype">
          <img src="@/assets/fullsizelogo.svg" style="position: absolute; left: calc(50% - 338 / 2); top: -30%; scale: 1.4;" alt="">
          <img src="@/assets/whitelogo.svg" alt="">
          <div class="loader">
            <div class="loaded" :style="`--progress: ${loadedProgress}%`"></div>
          </div>
      </div>
    </div>
</template>
<script>

import { initBackButton } from '@telegram-apps/sdk';


import {mockTelegramEnv, parseInitData, initUtils, initCloudStorage} from '@telegram-apps/sdk';
export default {
    name : "InitPage",
    data(){
      return{
        initData : null,
        string : "",
        loadedProgress: 0,
      }
    },
    async mounted() {
      try {
        
        this.initData = parseInitData(window.Telegram.WebApp.initData);
        this.loadedProgress = 5;
        if(!this.initData){
          location.reload();
        }
        let parsed = this.initData;
        
        if(process.env.NODE_ENV === "development"){
          let unparsed = window.Telegram.WebApp.initData;
        if(process.env.NODE_ENV != 'production'){
          mockTelegramEnv({
          themeParams: {
            accentTextColor: '#6ab2f2',
            bgColor: '#ffffff',
            buttonColor: '#5288c1',
            buttonTextColor: '#ffffff',
            destructiveTextColor: '#ec3942',
            headerBgColor: '#ffffff',
            hintColor: '#708499',
            linkColor: '#6ab3f3',
            secondaryBgColor: '#ffffff',
            sectionBgColor: '#ffffff',
            sectionHeaderTextColor: '#6ab3f3',
            subtitleTextColor: '#708499',
            textColor: '#f5f5f5',
          },
          initData: parsed,
          unparsed,
          version: '7.2',
          platform: 'tdesktop',
        });
        }
        }
        
        
        
        
        this.loadedProgress = 10;
        
        const [backButton] = initBackButton();
        const cloudStorage = initCloudStorage();
        this.loadedProgress = 20;
        window.Telegram.WebApp.expand();
        
        try {
          if(process.env.NODE_ENV == "production"){
            let cloudMode = await cloudStorage.get('dev_mode');
        
          this.$store.state.D_MODE = (cloudMode) ? cloudMode : "off";
           
          }
        

        this.$gram.WebApp.onEvent('fullscreenChanged',()=>{
          if(window.Telegram.WebApp.platform != "ios" && window.Telegram.WebApp.platform != "android"){
            this.$gram.WebApp.exitFullscreen();
          }
        })


        } catch (error) {
          console.log("older api");
          
        }
       
        
        this.loadedProgress = 20;
        let request = await this.$api.post("auth", {
          telegram_data: window.Telegram.WebApp.initData
        });
        this.loadedProgress = 25;
        backButton.on('click',()=>{
          this.$router.go(-1)
        })
        this.$events.on('hide_back_button',()=>{
          
          
          backButton.hide();
        })
        this.$events.on('show_back_button',()=>{
          
          
          backButton.show();
        })

        this.Telegram.WebApp.LocationManager.init(()=>{
          
          this.inited = this.Telegram.WebApp.LocationManager.isInited;
          this.granted = this.Telegram.WebApp.LocationManager.isAccessGranted;
          this.available = this.Telegram.WebApp.LocationManager.isLocationAvailable;
          
          if(this.Telegram.WebApp.LocationManager.isLocationAvailable){
            if(!this.Telegram.WebApp.LocationManager.isAccessGranted){
              // this.Telegram.WebApp.LocationManager.openSettings();
            }
            else{
              this.Telegram.WebApp.LocationManager.getLocation((data)=>{
                this.data = JSON.stringify(data)
                
                
              })
            }
            
          }
        
        })
        
        if(request.token){
        
          localStorage.setItem("token",request.token);
          this.$store.state.userInfo = request;
          this.loadedProgress = 50;
          if(this.$sentry){
            this.$sentryObj.setUser({ id: request._id, username: `${request.username}|${request.id}`});
            this.loadedProgress = 55;
          }
          let getUserShops = await this.$api.get('shops/me');
          this.loadedProgress = 80;
          if(getUserShops instanceof Array){
            this.$store.state.userShops = getUserShops;
          }
          
          this.loadedProgress = 85;
          
          // console.log(this.initData.user.languageCode)
            
          this.$i18n.locale = this.initData.user.languageCode
             
            
              
              
            if(parsed.startParam){
            if(parsed.startParam.includes('offer')){
              this.$router.push('/freelance')
            }
            else{
              let param = parsed.startParam;
            let command = param.split('place')[1];
            if(command.includes('shop')){
              let shop = command.split('shop')[1];
              this.$router.push('/shops/view/' + shop + '?from=/home');
            }
            if(command.includes('product')){
              let prod = command.split('product')[1];
              this.$router.push('/products/view/' + prod + '?from=/home');
            }
            }
          
          }
          else{
            this.loadedProgress = 90;
            this.$router.push('/home');
          }
            
          
          
         

        }
        else{
          if(request.error == "USER_HASH"){
            let utils = initUtils();
            utils.openTelegramLink("https://t.me/Tagito_bot")
          }
          this.string = request.message;
        }
      }
      catch (e){
        console.error(e);
        this.string = this.$t('system.error_on_init')
      }



    }
  }
</script>
<style scoped>
.loading-block{
  height: 100vh;
  display: flex;
  justify-content: center;
  background: linear-gradient(103.55deg, #0182A8 1.24%, #02AEBA 100%),
linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));

  align-items: center;
  padding: 20px;
  flex-direction: column; gap: 20px;
  width: 100%;
}
.loader{
  height: 6px;
  width: 100px;
  background-color: rgba(255,255,255,0.8);
  border-radius: 10px;
  overflow: hidden
}
.loaded{
  width: var(--progress);
  transition: .3s linear 0s;
  background-color: #fff;
  height: inherit;
}
.logotype{
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: center;
  margin-bottom: 80px;
}

@keyframes loading {
  0%{
    rotate: 0deg;

  }
  30%{
    transform: scale(1.1);
  }
  45%{
    rotate: 0;
    scale: 1;
  }
  50%{
    rotate: 360deg;

  }
  80%{

  }
  100%{
    rotate: 360deg;

  }
}
p{
  max-width: 300px;
  word-break: break-all;
}
</style>